import axios from 'axios';
import { Field, Formik, useFormikContext } from 'formik';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import qs from 'query-string';
import React from 'react';
import { Star } from '../../common';
import './RecipientRating.scss';

export const RecipientRating = ({ _id, token, rating }) => {
	const { illu_direction, illu_cyclist } = useStaticQuery(graphql`
		query {
			illu_direction: file(relativePath: { eq: "illu_direction.png" }) {
				childImageSharp {
					fixed(width: 261, quality: 100) {
						...GatsbyImageSharpFixed_withWebp_tracedSVG
					}
				}
			}
			illu_cyclist: file(relativePath: { eq: "illu_cyclist.png" }) {
				childImageSharp {
					fixed(width: 280, quality: 100) {
						...GatsbyImageSharpFixed_withWebp_tracedSVG
					}
				}
			}
		}
	`);

	const rateParcel = (payload) => {
		axios.get(
			`/.netlify/functions/rate-parcel?${qs.stringify({
				...payload,
				token,
				_id,
			})}`
		);
	};

	const hasComment = rating && rating.comment;
	const hasValue = rating && rating.value;

	const initialValues = {
		recipient_rating_comment: hasComment ? rating.comment : '',
		recipient_rating_value: hasValue ? Number(rating.value) : undefined,
	};

	const StarInput = ({ value }) => {
		const { handleChange, values } = useFormikContext();

		return (
			<>
				<input
					type="radio"
					name="recipient_rating_value"
					className="value-input"
					id={`star-${value}`}
					value={value}
					disabled={hasValue}
					onChange={handleChange}
				/>
				<label
					htmlFor={`star-${value}`}
					className={
						hasValue ? 'value-label value-label__disabled' : 'value-label'
					}
				>
					<Star checked={values.recipient_rating_value >= value} />
				</label>
			</>
		);
	};

	const RateUs = () => {
		const { handleSubmit } = useFormikContext();
		return (
			<div className="col-12">
				<h5>Értékeld a szolgáltatásunkat</h5>
				<p className="body1">
					Visszajelzésed fontos számunkra, hogy még tovább fejlődhessünk!
				</p>
				<div className="row" id="rating-content">
					<div className="col-6 align-items-center justify-content-center d-md-flex d-none">
						<Img fixed={illu_cyclist.childImageSharp.fixed} />
					</div>
					<div className="col-md-6 col-12">
						<div className="form-group text-left">
							<p className="caption mb-1" htmlFor="recipient_rating_comment">
								Szöveges értékelés
							</p>
							<Field
								id="recipient_rating_comment"
								component="textarea"
								rows={5}
								name="recipient_rating_comment"
								className="form-control"
							/>
						</div>
						<div className="form-group mt-4">
							<p
								id="rating-label"
								className="caption"
								htmlFor="recipient_rating_comment"
							>
								Pontozás
							</p>
							<div
								className={hasValue ? 'mt-2 values-disabled' : 'mt-2'}
								id="recipient_rating_value_container"
							>
								<StarInput value={1} />
								<StarInput value={2} />
								<StarInput value={3} />
								<StarInput value={4} />
								<StarInput value={5} />
							</div>
						</div>
						<button
							className="submit-button fullwidth mt-4"
							type="button"
							onClick={handleSubmit}
							disabled={hasValue && hasComment}
						>
							Értékelés küldése
						</button>
					</div>
				</div>
			</div>
		);
	};

	const YourRating = () => {
		return (
			<div className="col-12">
				<h5>Köszönjük, hogy értékelted szolgáltatásunkat</h5>
				<p className="body1">
					Visszajelzésed fontos számunkra, hogy még tovább fejlődhessünk!
				</p>
				<div className="row" id="rating-content">
					<div className="col-6 align-items-center justify-content-center d-md-flex d-none">
						<Img fixed={illu_cyclist.childImageSharp.fixed} />
					</div>
					<div className="col-6">
						<div className="form-group text-left">
							<p
								className="caption mb-2 rating-label"
								htmlFor="recipient_rating_comment"
							>
								Szöveges értékelésed
							</p>
							<p>{rating?.comment}</p>
						</div>
						<div className="form-group mt-4">
							<p
								className="caption rating-label"
								htmlFor="recipient_rating_comment"
							>
								Pontozás
							</p>
							<div className="mt-2" id="recipient_rating_value_container">
								<StarInput value={1} />
								<StarInput value={2} />
								<StarInput value={3} />
								<StarInput value={4} />
								<StarInput value={5} />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};

	const ThankYou = () => {
		const { values } = useFormikContext();

		return (
			<div className="d-flex" id="thank-you-container">
				<div className="align-items-center justify-content-center d-md-flex d-none">
					<Img fixed={illu_direction.childImageSharp.fixed} />
				</div>
				<div className="d-flex align-items-center justify-content-center">
					<div>
						<h5 className="fullwidth">Köszönjük az értékelést!</h5>
						{![4, 5].includes(values?.recipient_rating_value) ? (
							<>
								<p className="subtitle2" id="didyouknow">
									Tudtad, hogy az elégedett ügyfelek csupán 1%-a hagy pozitív
									értékelést?
								</p>
								<p className="body1">
									Segítsd a munkánkat azzal, hogy értékelsz minket a{' '}
									<strong>Google </strong> oldalán is!
								</p>
								<div>
									<button
										id="google-review"
										type="button"
										className="submit-button fullwidth"
									>
										<a
											href="https://g.page/r/Cctb873noRPvEB0/review"
											target="_blank"
											rel="noopener noreferrer"
										>
											Értékelést adok le
										</a>
									</button>
								</div>
							</>
						) : (
							<>
								<p className="subtitle2" id="itisimportant">
									Visszajelzésed fontos számunkra, hogy tovább fejlődhessünk!
								</p>
							</>
						)}
					</div>
				</div>
			</div>
		);
	};

	return (
		<div className="row card" id="recipient-rating">
			<Formik initialValues={initialValues} onSubmit={rateParcel}>
				{({ isSubmitting }) => {
					if (isSubmitting) {
						return <ThankYou />;
					}
					if (hasComment && hasValue) {
						return <YourRating />;
					}
					return <RateUs />;
				}}
			</Formik>
		</div>
	);
};

export default RecipientRating;
